:root {
  /* Font sizes */
  --font-size-small: 12px;
  --font-size-regular: 14px;
  --font-size-medium: 18px;
  --font-size-large: 24px;
  --font-size-xl: 28px;
  --font-size-xxl: 36px;
  --font-size-xxxl: 44px;

  /* Font weights */
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
}

/* Hide arrow from input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrow from input type number */
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=file] {
  color: transparent;
}

input[type=file]::-webkit-file-upload-button {
  display: none;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

@tailwind base;
@tailwind components;

@layer base {
  @font-face {
    font-family: 'Poppins';
    font-weight: 400;
    font-style: normal;
    src: url('./assets/fonts/Poppins-Regular.ttf');
  }
  
  @font-face {
    font-family: 'Poppins';
    font-weight: 500;
    font-style: normal;
    src: url('./assets/fonts/Poppins-Medium.ttf');
  }
  
  @font-face {
    font-family: 'Poppins';
    font-weight: 600;
    font-style: normal;
    src: url('./assets/fonts/Poppins-SemiBold.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 900;
    font-style: normal;
    src: url('./assets/fonts/Poppins-ExtraBold.ttf');
  }

  html {
    @apply font-poppins;
    font-weight: 400;
  }

  a {
    color: #00BFF0;
  }
  
  body {
    margin: 0;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fafafa;
  }

  h1, h2, h3, h4, h5, h6 {
    color: theme('colors.dark-blue');
    font-weight: var(--font-weight-semibold);
  }

  h1 {
    font-size: var(--font-size-xxxl);
  }

  h2 {
    font-size: var(--font-size-xxl);
  }

  h3 {
    font-size: var(--font-size-xl);
  }

  h4 {
    font-size: var(--font-size-large);
  }

  h5 {
    font-size: var(--font-size-medium);
  }

  h6 {
    font-size: var(--font-size-small);
  }

  p {
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-regular)
  }

  .bg-black {
    background-color: #000000;
  }

  .no-scroll {
    position: fixed;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
}

@layer components {
  .base-button {
    @apply btn btn-primary normal-case font-medium text-lg;
  }

  .base-link {
    @apply normal-case font-medium text-sm min-h-fit h-fit p-0 bg-transparent hover:no-underline hover:bg-transparent;
  }

  .card {
    @apply rounded-2xl p-8 bg-white;
  }
}

@tailwind utilities;
